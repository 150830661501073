import img1 from "../assets/images/portImages/acm.jpg";
import img2 from "../assets/images/portImages/typemaster.jpg";
import img3 from "../assets/images/portImages/acm.jpg";

const projects = [
    {
        id: 1,
        category: 'Web Development',
        image: img1,
        link1: 'https://www.google.com',
        link2: 'https://www.google.com',
        title: 'How to use WEb',
        text: 'Number onf jnonvdn lkdo'
    },
    {
        id: 2,
        category: 'Animation',
        image: img3,
        link1: 'https://www.google.com',
        link2: 'https://www.google.com',
        title: 'How to Animate in mata',
        text: 'Number onf jnonvdn lkdo'
    },
    {
        id: 3,
        category: 'Web Development',
        image: img2,
        link1: 'https://www.google.com',
        link2: 'https://www.google.com',
        title: 'How to use WEb',
        text: 'Number onf jnonvdn lkdo'
    },
    {
        id: 4,
        category: 'Animation',
        image: img2,
        link1: 'https://www.google.com',
        link2: 'https://www.google.com',
        title: 'How to use WEb',
        text: 'Number onf jnonvdn lkdo'
    },
]

export default projects;